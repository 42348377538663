// Sobrescribir las variables de Bootstrap
$primary: #b00020;
/* Cambiar el color primario de Bootstrap */
$secondary: black;

.card {
  border-color: #b00020 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 12px;
}

table {
  font-size: 12px;
}

// Importar Bootstrap con las variables modificadas
@import '~bootstrap/scss/bootstrap';

.gym-background-img {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url("../public/assets/img/main-img2.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.gym-content {
    position: relative;
    z-index: 1;
    padding: 20px;
    color: #fff;
    /* Ajusta el color del texto si es necesario */
}

.hero-section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* Ocupar toda la pantalla */
    color: white;
    text-align: center;
    position: relative;
    z-index: 1;
    //padding: 20px;
}

.hero-content {
    z-index: 2;
}

.hero-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
}

.hero-subtitle {
    font-size: 1.25rem;
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 30px;
}

.hero-btn {
    padding: 10px 40px;
    font-size: 1.2rem;
}

/* SUBSCRIPTIONS */
.plan-card {
    border-radius: 5px;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .plan-card:hover {
    transform: translateY(-10px);
  }
  
  .plan-card.selected {
    border: 2px solid #b00020; /* Cambia el borde cuando el plan esté seleccionado */
    box-shadow: 0 8px 16px rgba(0, 123, 255, 0.3); /* Resalta el plan seleccionado */
  }
  
  .plan-name {
    font-size: 1rem;
    font-weight: bold;
  }
  
  .plan-price {
    font-size: 1.5rem;
    font-weight: bold;
    //margin: 20px 0;
  }
  
  .plan-features {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .plan-features li {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .available-feature i {
    color: #28a745; /* Verde para las características disponibles */
    margin-right: 10px;
  }
  
  .unavailable-feature i {
    color: #dc3545; /* Rojo para las características no disponibles */
    margin-right: 10px;
  }
  
  .order-btn {
    background-color: #b00020; /* Color principal para el botón */
    border-radius: 50px;
  }  